import React from "react"
import { Link } from "gatsby"

const page = () => {
  return (

    <div>

        <section class="dark full-height 404">

                <div className="container container-small">

                    <h3>Whoops, there's nothing here!</h3>
                    <h6><Link to="/">Back Home</Link></h6>

                </div>	
                
        </section>

    </div>

  )
}

export default page
